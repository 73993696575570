document.addEventListener("turbo:load", () => {
  $(document).on('change', '#upload_job_attachments', function(event) {
    let target = event.currentTarget;
    let files = target.files;
    for (let x in files) {
      if (files[x].size > target.dataset.size) {
        alert(target.dataset.message);
        event.currentTarget.value = '';
        break;
      }
    }
  });

  // $(document).fancybox({
  //   parent: 'body',
  //   selector : '.fancybox',
  //   animationEffect : 'fade',
  //   buttons: [
  //     'zoom',
  //     'slideShow',
  //     'thumbs',
  //     'download',
  //     'close'
  //   ]
  // });

  const fancyOptions = {
    // Images: {
    //   // initialSize: "fit",
    // },
  };
  Fancybox.bind("[data-fancybox]", fancyOptions);
});
