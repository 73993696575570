// inspiration: https://jsfiddle.net/djibe89/bofcrp8v/
hookupTextareaAutosize = function() {
  !function(t,e,i,n){function s(e,i){this.element=e,this.$element=t(e),this.init()}var h="textareaAutoSize",o="plugin_"+h,r=function(t){return t.replace(/\s/g,"").length>0};s.prototype={init:function(){var i=parseInt(this.$element.css("paddingBottom"))+parseInt(this.$element.css("paddingTop"))+parseInt(this.$element.css("borderTopWidth"))+parseInt(this.$element.css("borderBottomWidth"))||0;r(this.element.value)&&this.$element.height(this.element.scrollHeight-i),this.$element.on("input keyup",function(n){var s=t(e),h=s.scrollTop();t(this).height(0).height(this.scrollHeight-i),s.scrollTop(h)})}},t.fn[h]=function(e){return this.each(function(){t.data(this,o)||t.data(this,o,new s(this,e))}),this}}(jQuery,window,document);

  // Initialize Textarea
  $('.textarea-autosize').textareaAutoSize();
  // todo: fix this face condition -- shouldn't need to be in setTimeout
  setTimeout(function() {$('.textarea-autosize').trigger('input');}, 500);
}

document.addEventListener("turbo:load", () => {
  hookupTextareaAutosize();
  console.log("Textarea autosize hooked up on turbo:load event");
});

// Run hookupTextareaAutosize on turbo:frame-render for Turbo Stream updates
document.addEventListener("turbo:frame-render", () => {
  hookupTextareaAutosize();
  console.log("Textarea autosize hooked up on turbo:frame-render event");
});

// Optionally, run hookupTextareaAutosize before a stream is rendered
document.addEventListener("turbo:before-stream-render", () => {
  hookupTextareaAutosize();
  console.log("Textarea autosize hooked up on turbo:before-stream-render event");
});